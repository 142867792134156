export const NOTIFICATION_TYPE_USER_FOLLOW_YOU = 'USER_FOLLOW_YOU'
export const NOTIFICATION_TYPE_POST_UPVOTED = 'POST_UPVOTED'
export const NOTIFICATION_TYPE_ARTICLE_LIKED = 'ARTICLE_LIKED'
export const NOTIFICATION_TYPE_POST_NEW_COMMENT = 'POST_NEW_COMMENT'
export const NOTIFICATION_TYPE_ARTICLE_NEW_COMMENT = 'ARTICLE_NEW_COMMENT'
export const NOTIFICATION_TYPE_POST_COMMENT_REPLIED = 'POST_COMMENT_REPLIED'
export const NOTIFICATION_TYPE_ARTICLE_COMMENT_REPLIED = 'ARTICLE_COMMENT_REPLIED'
export const NOTIFICATION_TYPE_POST_SHARED_TWITTER = 'POST_SHARED_TWITTER'
export const NOTIFICATION_TYPE_POST_SHARED_FACEBOOK = 'POST_SHARED_FACEBOOK'
export const NOTIFICATION_TYPE_ARTICLE_SHARED_TWITTER = 'ARTICLE_SHARED_TWITTER'
export const NOTIFICATION_TYPE_ARTICLE_SHARED_FACEBOOK = 'ARTICLE_SHARED_FACEBOOK'
export const NOTIFICATION_TYPE_ARTICLE_PUBLISHED = 'ARTICLE_PUBLISHED'
export const NOTIFICATION_TYPE_ARTICLE_SUBMITTED = 'ARTICLE_SUBMITTED'
export const NOTIFICATION_TYPE_ARTICLE_SUBMITTED_ADMIN = 'ARTICLE_SUBMITTED_ADMIN'
export const NOTIFICATION_TYPE_ARTICLE_REJECTED = 'ARTICLE_REJECTED'
export const NOTIFICATION_TYPE_EVENT_PUBLISHED = 'EVENT_PUBLISHED'
export const NOTIFICATION_TYPE_EVENT_SUBMITTED = 'EVENT_SUBMITTED'
export const NOTIFICATION_TYPE_EVENT_REJECTED = 'EVENT_REJECTED'
export const NOTIFICATION_TYPE_LIVESTREAM_COMING = 'LIVESTREAM_COMING'
export const NOTIFICATION_TYPE_LIVESTREAM_LIVE = 'LIVESTREAM_LIVE'
export const NOTIFICATION_TYPE_LIVESTREAM_WAS_LIVE = 'LIVESTREAM_WAS_LIVE'
export const NOTIFICATION_TYPE_TOKEN_PUBLISHED = 'TOKEN_PUBLISHED'
export const NOTIFICATION_TYPE_TOKEN_REJECTED = 'TOKEN_REJECTED'
export const NOTIFICATION_TYPE_TOKEN_SUBMITTED = 'TOKEN_SUBMITTED'
export const NOTIFICATION_TYPE_TOKEN_SUBMITTED_ADMIN = 'TOKEN_SUBMITTED_ADMIN'
export const NOTIFICATION_TYPE_PROJECT_PUBLISHED = 'PROJECT_PUBLISHED'
export const NOTIFICATION_TYPE_PROJECT_REJECTED = 'PROJECT_REJECTED'
export const NOTIFICATION_TYPE_PROJECT_SUBMITTED = 'PROJECT_SUBMITTED'
export const NOTIFICATION_TYPE_PROJECT_SUBMITTED_ADMIN = 'PROJECT_SUBMITTED_ADMIN'
export const NOTIFICATION_TYPE_ONBOARDING_POST = 'USER_ONBOARDING_POST'
export const NOTIFICATION_TYPE_ONBOARDING_SKIPPED_REMINDER = 'USER_SKIPPED_POST_REMINDER'
export const NOTIFICATION_TYPE_POST_TRENDING = 'POST_TRENDING'
export const NOTIFICATION_TYPE_COMMUNITY_SUBMITTED_ADMIN = 'COMMUNITY_SUBMITTED_ADMIN'
export const NOTIFICATION_TYPE_COMMUNITY_SUBMITTED = 'COMMUNITY_SUBMITTED'
export const NOTIFICATION_TYPE_COMMUNITY_REJECTED = 'COMMUNITY_REJECTED'
export const NOTIFICATION_TYPE_COMMUNITY_PUBLISHED = 'COMMUNITY_PUBLISHED'

export const NOTIFICATION_ORIGIN_TWITTER = 'twitter'
export const NOTIFICATION_ORIGIN_FACEBOOK = 'facebook'

export const NOTIFICATION_TIME_TO_MARK_READ = 2 * 1000 // 2 seconds

export const NOTIFICATION_WEBSOCKET_CHANNEL = 'receive-notification'
